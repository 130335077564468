import React, { useCallback, useState } from 'react'

import styled from 'styled-components'
import Image from 'next/image'
import { Launchpad } from 'state/types'
import { Button, Flex, Skeleton } from '@pancakeswap/uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useBuyLaunchpad, useLaunchpadInfo } from 'views/Home/hooks/useBuyLaunchpad'
import { USDT as usdtToken } from '@pancakeswap/tokens'
import { useBalance } from 'wagmi'
import { ChainId, CurrencyAmount } from '@pancakeswap/sdk'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { useJoiNFTContract } from 'hooks/useContract'
import { formatBigInt, formatNumber } from '@pancakeswap/utils/formatBalance'
import useTokenBalance from 'hooks/useTokenBalance'
import { useLogged } from 'hooks/useLogged'
import ConnectWalletAndLoginButton from 'components/ConnectWalletAndLoginButton'
import { useGetReferrer } from 'views/Referral/hooks/useReferral'
import Link from 'next/link'

const BlockNFTItem = styled.div`
  @media screen and (max-width: 1199px) {
    width: calc(100% / 2 - 12.5px);
    max-width: 450px;
    &:nth-child(2n) {
      margin: 0px 0px 25px 0px;
    }
    &:nth-child(3) {
      margin: 0px 25px 25px 0px !important;
    }
  }
  @media screen and (max-width: 767px) {
    &:nth-child(3n) {
      margin: 0px 0px 50px 0px !important;
    }
    width: 100%;
    max-width: 450px;
    margin: 0px 0px 25px 0px;
  }
  @media screen and (max-width: 576px) {
    max-width: 350px;
  }
  width: calc(100% / 3 - 16.75px);
  background: ${({ theme }) => theme.colors.backgroundElement};
  border-radius: 18px;
  margin: 0px 25px 25px 0px;
  position: relative;
  transition: 0.4s all;
  overflow: hidden;
  &:nth-child(3n) {
    margin: 0px 0px 25px 0px;
  }
`
const BlockWrapNFT = styled.div`
  position: relative;
  z-index: 2;
`
const BlockWrapContent = styled.div`
  position: relative;
  z-index: 2;
  border-radius: 0px 0px 18px 18px;
  background: ${({ theme }) => theme.colors.backgroundElement};
`
const BlockItemImage = styled(Image)`
  width: 100%;
  height: 500px;
  object-fit: cover;
  user-select: none;
`

const BlockItemVideo = styled.video`
  width: 100%;
  background: #000;
  height: 500px;
  object-fit: contain;
  user-select: none;
  @media screen and (max-width: 576px) {
    height: 400px;
  }
`

const BlockInfoNFT = styled.div`
  padding: 5px 15px 10px 15px;
`
const BlockName = styled.div`
  font-size: 1.8rem;
  line-height: calc(1.8rem * 1.25);
  font-family: SUISSE_BOLD;
  text-align: center;
  color: rgba(23, 54, 38);
  margin: 10px 0px 15px 0px;
  color: ${({ theme }) => theme.colors.color};
  @media screen and (max-width: 479px) {
    font-size: 1.6rem;
    line-height: calc(1.6rem * 1.25);
    margin: 0px 0px 10px 0px;
  }
`
const BlockPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`
const BlockBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
  button:disabled {
    border-radius: 8px;
    color: #888 !important;
  }
`

const BlockSelectToken = styled(Flex)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5px 0px;
  width: fit-content;
  width: 100%;
  flex-wrap: wrap;
`
const BlockTokenItem = styled(Flex) <{ active?: boolean }>`
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: calc(100% / 2 - 10px);
  padding: 5px 12.5px;
  border-radius: 10px;
  background: ${({ active, theme }) => (active ? 'rgba(101,212,110, 0.15)' : theme.colors.backgroundNonActive)};
  transition: 0.4s all;
  border: 2px solid ${(props) => (props.active ? 'rgba(101,212,110)' : 'transparent')};
  color: ${({ theme }) => theme.colors.color};
  margin: 0px 5px;
  @media screen and (max-width: 991px) {
    width: 100%;
    margin: 5px 0px;
  }
`
const BlockImageToken = styled(Image)`
  @media screen and (max-width: 479px) {
    width: 20px;
    height: 20px;
  }
  width: 24px;
  height: 24px;
  margin: 0px 12.5px 0px 0px;
  user-select: none;
`
const BlockWrapPriceToken = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const BlockPriceToken = styled.div`
  @media screen and (max-width: 479px) {
    font-size: 1.2rem;
    line-height: calc(1.2rem * 1.25);
  }
  font-family: SUISSE_BOLD;
  user-select: none;
  font-size: 1.25rem;
  line-height: calc(1.25rem * 1.25);
`
const BlockLabelToken = styled.div`
  @media screen and (max-width: 479px) {
    font-size: 1.1rem;
    line-height: calc(1.1rem * 1.25);
  }
  margin: 0px 0px 2.5px 0px;
  font-family: SUISSE_MEDIUM;
  font-size: 1.1rem;
  line-height: calc(1.1rem * 1.25);
`

const BlockItem = styled.div`
  position: realtive;
  z-index: 2;
`

const WrapName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const BlockCountNFT = styled.div`
  background: rgb(39, 68, 33);
  color: #fff;
  font-family: SUISSE_BLACK;
  padding: 2.5px 5px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 1.25rem;
  line-height: calc(1.25rem * 1.25);
  margin: -2px 0px 0px 7.5px;
`

export const DirectSetReferral = styled(Link)`
  height: 45px;
  background: rgba(242, 187, 66);
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SUISSE_BOLD;
  text-transform: uppercase;
`

interface NFTItemProps {
  nft: Launchpad
  isLast: boolean
  isMint?: boolean
  qty?: number
}

enum COIN {
  BNB = 'BNB',
  USDT = 'USDT',
}
const NFTItem: React.FC<NFTItemProps> = ({ nft, isMint, qty }) => {
  const [coin, setCoin] = useState(COIN.BNB)

  const { priceUSDT, priceBNB } = useLaunchpadInfo(nft.sort)

  const chainId = ChainId.BSC
  const contract = useJoiNFTContract()
  const { logged, isWrongAccount } = useLogged()
  const { account } = useActiveWeb3React()
  const { onBuyBNB, onBuyToken, isBuying } = useBuyLaunchpad(nft.sort)
  const { balance: userUsdtBalance } = useTokenBalance(usdtToken[chainId].address)
  const validUsdt = userUsdtBalance?.gte(priceUSDT?.toString())

  const bnbBalance = useBalance({ address: account, chainId: ChainId.BSC })
  const validBnb = bnbBalance?.data?.value > priceBNB
  const disabledBuy = coin === COIN.BNB ? !validBnb : !validUsdt

  const parsedAmount = CurrencyAmount.fromRawAmount(usdtToken[chainId], priceUSDT ?? 0n)
  const [approval, approveCallback] = useApproveCallback(parsedAmount, contract.address)

  const showApproval =
    coin === COIN.USDT && (approval === ApprovalState.NOT_APPROVED || approval === ApprovalState.PENDING)

  const handleBuy = useCallback(
    (coinSelected: COIN) => {
      if (coinSelected === COIN.BNB) return onBuyBNB(priceBNB?.toString())

      return onBuyToken()
    },
    [onBuyBNB, onBuyToken, priceBNB],
  )

  const { except, referrer } = useGetReferrer(account)

  return (
    <BlockNFTItem>
      <BlockWrapNFT>
        <BlockItem>
          {nft?.file?.completedUrl ? (
            <BlockItemVideo style={{ objectFit: 'contain' }} muted loop playsInline autoPlay preload="none">
              <source src={nft?.file?.completedUrl} type="video/mp4" />
            </BlockItemVideo>
          ) : (
            <BlockItemImage
              src={nft?.image?.completedUrl}
              alt={nft?.contract?.address}
              width="0"
              height="0"
              sizes="100vw"
            />
          )}
        </BlockItem>
      </BlockWrapNFT>

      <BlockWrapContent>
        <BlockInfoNFT>
          <WrapName>
            <BlockName>{nft?.name}</BlockName>
            <BlockCountNFT>{formatNumber(Number(qty), 0, 0)}</BlockCountNFT>
          </WrapName>
          <BlockPrice>
            <BlockSelectToken justifyContent="space-between">
              <BlockTokenItem active={coin === COIN.BNB} onClick={() => setCoin(COIN.BNB)}>
                <BlockImageToken src="/images/icons/icBnb.svg" alt="" width={24} height={24} />
                <BlockWrapPriceToken>
                  <BlockLabelToken>{isMint ? 'Mint' : 'Buy'} with</BlockLabelToken>
                  {!priceBNB ? (
                    <Skeleton width="100px" height="20px" />
                  ) : (
                    <BlockPriceToken>{formatBigInt(priceBNB, 6)} BNB</BlockPriceToken>
                  )}
                </BlockWrapPriceToken>
              </BlockTokenItem>
              {!isMint && (
                <BlockTokenItem active={coin === COIN.USDT} onClick={() => setCoin(COIN.USDT)}>
                  <BlockImageToken src="/images/icons/icUsdt.svg" alt="" width={24} height={24} />
                  <BlockWrapPriceToken>
                    <BlockLabelToken>Buy with</BlockLabelToken>
                    {!priceUSDT ? (
                      <Skeleton width="100px" height="20px" />
                    ) : (
                      <BlockPriceToken>{formatBigInt(priceUSDT, 6)} USDT</BlockPriceToken>
                    )}
                  </BlockWrapPriceToken>
                </BlockTokenItem>
              )}
            </BlockSelectToken>
          </BlockPrice>
          <BlockBtn>
            {!logged ? (
              <ConnectWalletAndLoginButton width="100%" />
            ) : isWrongAccount ? (
              <Button width="100%" disabled>
                Please change to the correct wallet account
              </Button>
            ) : showApproval ? (
              <Button
                style={{ boxShadow: 'none' }}
                width="100%"
                onClick={approveCallback}
                isLoading={approval === ApprovalState.PENDING}
                disabled={!validUsdt}
              >
                {!validUsdt ? 'Insufficient balance' : approval === ApprovalState.PENDING ? 'Approving ...' : 'Approve'}
              </Button>
            ) : (
              <>
                {(except || !!referrer) ? (
                  <>
                    {isMint ? (
                      <Button
                        style={{ boxShadow: 'none' }}
                        width="100%"
                        onClick={() => handleBuy(coin)}
                        disabled={disabledBuy}
                        isLoading={isBuying}
                      >
                        {disabledBuy ? 'Insufficient balance' : isBuying ? 'Minting ...' : 'Mint'}
                      </Button>
                    ) : (
                      <Button
                        style={{ boxShadow: 'none' }}
                        width="100%"
                        onClick={() => handleBuy(coin)}
                        disabled={disabledBuy}
                        isLoading={isBuying}
                      >
                        {disabledBuy ? 'Insufficient balance' : isBuying ? 'Buying ...' : 'Buy'}
                      </Button>
                    )}
                  </>
                ) : (
                  <DirectSetReferral href="/referral">Please set your referrer first</DirectSetReferral>
                )}
              </>
            )}
          </BlockBtn>
        </BlockInfoNFT>
      </BlockWrapContent>
    </BlockNFTItem>
  )
}

export default NFTItem
