import { useToast } from '@pancakeswap/uikit'
import { useCallback, useState } from 'react'
import { fetchReferrerOf, requestSetReferrer } from 'state/user/helpers'
import useSWR from 'swr'

export function useReferrerOf(address: string) {
  const { data, mutate, isLoading } = useSWR(address ? [address, 'ReferrerOf'] : null, () => fetchReferrerOf(address))

  return {
    isLoading,
    referrer: data?.referrer ?? '',
    exceptSetRef: data?.exceptSetRef ?? false,
    mutate,
  }
}

export const useSetReferrer = (referrer: string, onDone?: () => void) => {
  const [isLoading, setIsLoading] = useState(false)

  const { toastSuccess, toastError } = useToast()

  const handleSetReferrer = useCallback(async () => {
    setIsLoading(true)
    try {
      await requestSetReferrer(referrer)
      onDone?.()
      toastSuccess('Referrer set successfully')
    } catch (error: any) {
      toastError(error?.message || "Can't set referrer")
    } finally {
      setIsLoading(false)
    }
  }, [onDone, referrer, toastError, toastSuccess])

  return { isLoading, onSetReferrer: handleSetReferrer }
}

export const useGetReferrer = (account: string) => {
  const { isLoading, referrer, exceptSetRef, mutate } = useReferrerOf(account)

  return {
    isLoading,
    except: exceptSetRef,
    referrer,
    refresh: mutate,
    isNeedSetRef: !referrer && !exceptSetRef,
  }
}

export const useIsValidRef = () => {
  return useCallback(async (ref: string) => {
    const rst = await fetchReferrerOf(ref)

    return rst.referrer || rst.exceptSetRef
  }, [])
}
